import React from 'react'
import useSharesPage from '../../utils/use-shares-page'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'

export default function AGM(props: PageProps) {
  const sharesPage = useSharesPage()
  const dividendPolicyPage = props.data.dividendPolicyPage

  return (
    <Layout
      location={props.location}
      heros={dividendPolicyPage.hero}
      herosFallbackTitle={sharesPage.title}
      herosFallbackSecondaryTitle={dividendPolicyPage.title}
      tabs="shareInfo"
      showStock
    >
      <div className="container flex flex-col mx-auto mt-8 lg:mt-4 mb-24">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 order-2">
          {dividendPolicyPage.body.childMarkdownRemark ? (
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 text-sm prose dark:prose-dark">
              <div
                dangerouslySetInnerHTML={{
                  __html: dividendPolicyPage.body.childMarkdownRemark.html
                }}
                className="mx-auto"
              />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    dividendPolicyPage: contentfulCustomPage(slug: { eq: "dividend-policy" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      hero {
        ...HeroFragment
      }
    }
  }
`
